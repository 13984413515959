import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { DatePickerField } from '../../components/Common/DatePicker';
import { CustomSelect } from './CustomSelect';
import { assignToProject } from '../../store/records';
import { findRelatedRetirement } from '../../store/projects';
import ButtonLoader from '../../components/Common/ButtonLoader';
import { format } from '../../helpers/formatter';

const Asterix = styled.span`
  color: red;
`;

const BottomRow = styled(Row)`
  display: grid;
  place-content: end;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  display: grid;
  padding: 0 28px;
`;

const SaveButton = styled(Button)`
  width: 84px;
  height: 40px;
  padding: 0px;
  font-size: 16px;
  border-radius: 8px;
`;

const ProjectRow = styled.div`
  display: flex;
  background-color: rgba(237, 239, 242, 1);
  border-radius: 8px;
  padding: 12px;
`;

const MutedText = styled.p`
  color: rgba(111, 114, 118, 1);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

const projectOptions = (projects) =>
  projects.map((project) => ({
    value: project,
    label: project.code,
  }));

const AssignToProjectForm = ({ data, params, selectedRecords }) => {
  const dispatch = useDispatch();

  const { isLoading: isLoadingRequest } = useSelector((state) => ({
    error: state.records.error,
    isLoading: state.records.isLoading,
  }));

  const { relatedRetirement } = useSelector((state) => ({
    relatedRetirement: state.projects.relatedRetirement?.results[0],
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      project: null,
      assignedAt: moment(new Date()).format('YYYY.MM.DD'),
      offset: '',
      note: '',
      recordIds: selectedRecords.map((record) => record.id),
    },
    validationSchema: Yup.object({
      project: Yup.object().required('Please select the project'),
      offset: Yup.number()
        .required('Please enter an offset')
        .max(
          selectedRecords.reduce((sum, o) => sum + +o.totalRemaning, 0),
          'Cannot be more than total remaining carbon or remaining value of assigned project',
        )
        .when('project', (project) => {
          if (project) {
            return Yup.number()
              .required('Please enter an offset')
              .min(0.5)
              .max(
                Math.min(
                  selectedRecords.reduce((sum, o) => sum + +o.offset, 0),
                  (validation.values.project &&
                    validation.values.project.remaning) ||
                    0,
                ),
                'Cannot be more than total remaining carbon or remaining value of assigned project',
              )
              .typeError('You must specify a number');
          }
        })
        .typeError('You must specify a number'),
      assignedAt: Yup.date().nullable().required('Assign Date is required'),
      note: Yup.string().max(1000, 'Note cannot exceed 1000 characters'),
    }),
    onSubmit: (formParams) => {
      const { assignedAt, offset, recordIds, project, note } = formParams;
      const { id, code } = project;
      dispatch(
        assignToProject({
          id,
          params: {
            assignedAt: moment.utc(assignedAt, 'YYYY.MM.DD').format(),
            offset,
            indulgenceIds: recordIds,
            note,
          },
          projectName: code,
          queryParams: params,
        }),
      );
    },
  });

  useEffect(() => {
    const { project, assignedAt } = validation.values;

    if (project && assignedAt) {
      dispatch(findRelatedRetirement({ projectId: project.id, assignedAt }));
    }
  }, [validation.values.project, validation.values.assignedAt, dispatch]);

  useEffect(() => {
    if (relatedRetirement?.note) {
      validation.setFieldValue('note', relatedRetirement.note);
    } else {
      validation.setFieldValue('note', '');
    }
  }, [relatedRetirement?.note]);

  return (
    <FormikProvider value={validation}>
      <FormContainer>
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col>
              <FormGroup>
                <MutedText>
                  Project No
                  <Asterix>*</Asterix>
                </MutedText>
                <div className="input-group">
                  <CustomSelect
                    inputId="project"
                    name="project"
                    onChange={(value) => {
                      validation.setFieldValue('project', value.value);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.project}
                    options={projectOptions(data)}
                    invalid={
                      !!(
                        validation.touched.project && validation.errors.project
                      )
                    }
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          {validation.values.project && (
            <ProjectRow className="mb-3">
              <Col xs={6}>
                <MutedText className="mb-1">Project Name</MutedText>
                <span style={{ fontWeight: '500', fontSize: '14px' }}>
                  {validation.values.project.title}
                </span>
              </Col>
              <Col xs={6}>
                <MutedText className="mb-1">Total tonnage (tonne)</MutedText>
                <span style={{ fontWeight: '500', fontSize: '14px' }}>
                  {format(validation.values.project.remaning)}
                </span>
              </Col>
            </ProjectRow>
          )}
          <Row>
            <Col>
              <div className="mb-3">
                <MutedText>
                  Assign Date
                  <Asterix>*</Asterix>
                </MutedText>
                <DatePickerField
                  onChange={(value) => {
                    validation.setFieldValue('assignedAt', value);
                  }}
                  name="assignedAt"
                  placeholderText="Assign Date"
                />
                {relatedRetirement && (
                  <p style={{ color: 'gray', fontSize: '12px' }}>
                    Retirement already exists for this date
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <MutedText>
                  Offset Carbon to the Project
                  <Asterix>*</Asterix>
                </MutedText>
                <Input
                  name="offset"
                  className="form-control"
                  placeholder="Offset"
                  type="offset"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.offset || ''}
                  invalid={
                    !!(validation.touched.offset && validation.errors.offset)
                  }
                />
                {validation.touched.offset && validation.errors.offset ? (
                  <FormFeedback type="invalid">
                    {validation.errors.offset}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <MutedText>Note</MutedText>
                <Input
                  type="textarea"
                  name="note"
                  className="form-control"
                  placeholder="Provide additional information for the Retirement Request"
                  maxLength="1000"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.note || ''}
                  invalid={
                    !!(validation.touched.note && validation.errors.note)
                  }
                />
                {validation.touched.note && validation.errors.note ? (
                  <FormFeedback type="invalid">
                    {validation.errors.note}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <BottomRow>
            <Col>
              <SaveButton
                disabled={isLoadingRequest}
                type="submit"
                color="primary"
              >
                {isLoadingRequest ? <ButtonLoader isShown /> : 'Assign'}
              </SaveButton>
            </Col>
          </BottomRow>
        </Form>
      </FormContainer>
    </FormikProvider>
  );
};

export default AssignToProjectForm;

AssignToProjectForm.propTypes = {
  data: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  selectedRecords: PropTypes.array.isRequired,
};
